import { Link, navigate } from "gatsby"
import React, { useCallback, useState, useContext, useEffect } from "react"
// import { Helmet } from "react-helmet"
import { useForm } from "react-hook-form"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import StateContext from "../context/state/StateContext"
import { getUser, makeLogin } from "../helpers/DrupalHelper"
import LayoutMinimal from "../components/Layout/Minimal"
import Button from "../components/Elements/Button"
import ButtonLite from "../components/Elements/ButtonLite"
import FloatingInput from "../components/Elements/Form/FloatingInput"
import NotificationContext from "../context/Notification/NotificationContext"
// import posthog from "posthog-js"
// import { ClerkProvider, SignIn, useAuth } from "@clerk/clerk-react"
// import axios from "axios"

// const PUBLISHABLE_KEY = "pk_test_dW5pcXVlLWVhcndpZy02NC5jbGVyay5hY2NvdW50cy5kZXYk"

const Login = ({ location }) => {
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const { state, dispatch } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)
  const { authentication, dispatchAuthentication, runLogin } = useContext(
    AuthenticationContext
  )
  
  const [notMatch, setNotMatch] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = async data => {
    setLoading(true)
    setNotMatch(false)
    await makeLogin(state.token, data.email, data.password).then(async res => {
      let dataL = await res.json()

      if (res.status === 200) {
        dispatch({ type: "SET_TOKEN", payload: dataL.csrf_token })
       
        await getUser(dataL.csrf_token).then(async data => {
          if (data.isAuthenticated === true) {
            sessionStorage.setItem("user", JSON.stringify(data))
            toggleNotification({
              content: `Welcome ${data.firstname}, login successful.`,
            })
            dispatchAuthentication({
              type: "LOGIN",
              payload: data,
            })
          
            if (typeof window !== "undefined" && window.posthog) {
              console.log("PH is available");
                // Identify a user in PostHog
              //----------------------------------------------------------------------------------------------------------------------
              const userData = data;

              // Construct a unique identifier
              const distinctId = `user_${userData.i}`;

              const brandIds = userData?.brands?.map(brand => brand.id).join(', ')
              const companyIds = userData?.companies?.map(company => company.id).join(', ')

              const brandTitles = userData?.brands?.map(brand => brand.title).join(', ')
              const companyTitles = userData?.companies?.map(company => company.title).join(', ')

              // Construct the user properties object
              const userProperties = {
                isAuthenticated: userData.isAuthenticated,
                email: userData.email,
                name: `${userData.firstname} ${userData.lastname}`,
                firstname: userData.firstname,
                lastname: userData.lastname,
                userId: userData.i,
                role: userData._r.join(', '), // Roles as a comma-separated string
                lastAccess: userData.last_access,
                status: userData.status,
                accountCompletion: userData.account_completion,
                primaryCompany: userData.primary_company,
                companies: userData.companies.map(company => ({
                  id: company.id,
                  title: company.title,
                })),

                // Brand details (only id and title)
                brands: userData.brands.map(brand => ({
                  id: brand.id,
                  title: brand.title,
                })),

                // Retailers (if available)
                retailers: userData.retailers,

                // // Submenu details
                // submenu: userData.submenu.map(item => ({
                //   tagTitle: item.tag_title,
                //   tagId: item.tag_id,
                // })),

              };

              // Identify the user in PostHog
              window.posthog.identify(distinctId, userProperties);

              // Capture a login event with minimal brand information
              window.posthog.capture(`Logged In (Opened Session) `, {
                email: userData.email,
                name: `${userData.firstname} ${userData.lastname}`, 
                id: userData.i,
               brandIds: brandIds,
                companyIds: companyIds,
                companies: userData.companies.map(company => ({
                  id: company.id,
                  title: company.title,
                })),
                brands: userData.brands.map(brand => ({
                  id: brand.id,
                  title: brand.title,
                })),
                brandTitles: brandTitles,
                companyTitles: companyTitles,
              });

            } else {
            console.error("PostHog is not available");
          }
            
            runLogin()
            navigate("/")
          }
        })
      } else {
        // dispatchAuthentication({
        //   type: "LOGIN",
        //   payload: data,
        // })
        // console.log(dataL)
        setNotMatch(dataL.message)
        //  navigate("/login")
      }
      reset()
    })
    setLoading(false)
  }
  useEffect(() => {
    if (location?.state?.logOut) {
      toggleNotification({
        content: `Succesfully Log out.`,
      })
    }
    // console.log(authentication)
    // console.log("authentication")
  }, [])
  useEffect(() => {
    // console.log(location?.state?.logOut)
    if (!location?.state?.logOut) {
      if (authentication.currentUserData.isAuthenticated) {
        navigate("/")
      }
    }

    // console.log(authentication)
    // console.log(authentication)
    // console.log("authentication")
  }, [authentication])
  // if (loading && isSignedIn)
  //   return (
  //     <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
  //       <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8"></div>
  //     </div>
  //   )
  return (
    <>
    {
      true && (
      // !isSignedIn && (
    <LayoutMinimal
      title={"Login to your Account"}
      footer={"©2025 O2O. All rights reserved."}
      metaTitle={"Login to your O2O account"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FloatingInput
          label="Email address"
          hookForm
          name="email"
          // type="email"
          register={register}
          error={errors["email"]}
          required
        />
        <FloatingInput
          label="Your Password"
          hookForm
          name="password"
          type="password"
          login
          register={register}
          error={errors["password"]}
          description={
            <Link
              to="/forgot-password"
              className="hover hover-red hover-thin !text-[#e43d30]"
            >
              Forgotten your password?
            </Link>
          }
        />

        {notMatch && (
          <div className="flex justify-center items-center mt-4">
            <p className="text-red-600">{notMatch}</p>
          </div>
        )}
        <div className="flex justify-center items-center mt-6">
          <Button red type="submit">
            Login
          </Button>
        </div>
      </form>
      <div className="border border-t-1 border-l-0 border-r-0 border-b-0 border-[#EBEBEB] mt-[30px] mb-[30px]">
        <div className="rounded-[8px] bg-[rgba(0,0,0,.02)] mt-[30px] py-[30px] px-[30px] text-center flex flex-col items-center">
          <h3 className="text-med-20 !text-[15px] mb-5 mt-0 !leading-[15px]">
            Don't already have an account?
          </h3>
          <ButtonLite callback={() => navigate("/register")}>
            Register now
          </ButtonLite>
        </div>
      </div>
    </LayoutMinimal>
    
    )}
    </>
  )
  
}

export default Login
